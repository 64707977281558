import { useUserStore } from '@/stores/userStore';

export default defineNuxtRouteMiddleware(async (to) => {
  // Only apply this middleware to team routes
  if (!to.path.startsWith('/team/')) {
    return;
  }

  const teamId = to.params.id as string;
  if (!teamId) {
    return navigateTo('/');
  }

  // Get the current user and client
  const user = useSupabaseUser();
  const client = useSupabaseClient();
  const userStore = useUserStore();

  if (!user.value) {
    return navigateTo('/login');
  }

  // Server-side verification of team membership
  const { data: teamMembership, error } = await client
    .from('group_users')
    .select('group_id, role')
    .eq('group_id', teamId)
    .eq('user_id', user.value.id)
    .single();

  if (error || !teamMembership) {
    console.error('Team access denied:', error?.message || 'No team membership found');
    throw createError({
      statusCode: 404,
      message: 'Team not found'
    });
  }

  // If we have access, ensure the team is loaded in the store
  const { data: teamData } = await client
    .from('groups')
    .select('*')
    .eq('id', teamId)
    .single();

  if (teamData) {
    // Update the store with the team data
    if (!userStore.teams.some(t => t.id === teamData.id)) {
      userStore.teams = [...userStore.teams, teamData];
    }
    userStore.setSelectedTeam(teamData);
  }
});
